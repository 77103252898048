.errorMsg {
	position: relative;
	width: auto;
	display: flex;
	justify-content: center;
	align-items: center;

	margin-top: 7rem;
}

.errorCode {
	font-size: 2rem;
	color: #888;
}

.errorDescription {
	font-size: 1.2rem;
}

.divider {
	width: 2px;
	height: 100%;
	background-color: var(--clgra);
	padding: 1.6rem 0;
	margin: 0 1rem;
}

.errorBackLink {
	position: relative;
	font-size: 1.2rem;
	display: flex;
	justify-content: center;
	margin-top: 3rem;
}

.background {
	position: fixed;
	left: 50%;
	transform: translateX(-50%);

	width: 70%;
	max-width: 800px;
	height: auto;

	z-index: -1;
	filter: brightness(65%) blur(16px);
}

.body {
	background-color: unset !important;
	min-height: 84.5vh !important;
}

@media (max-width: 800px) {
	.errorMsg {
		flex-direction: column;
		text-align: center;
	}
	.divider {
		width: 100%;
		max-width: 300px;
		height: 2px;
		padding: 0;
		margin: 0.5rem 0;
	}
}
